.main-table {
    padding: 30px;
}

.timepicker{
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 400px;
}

.statistics{
    width: 400px;
    margin-right: 20px;
}

.input {
    width: 600px;
    margin: 0 auto;
}

.input-comment {
    margin-top: 20px;
}

.align-left {
    float: right;
    margin-top: 20px;
}

#main-header {
    background-color: #1145CC;
    color: white;
    font-size: 22px;
}

#global-footer {
    color: darkgrey;
    text-align: center;
}